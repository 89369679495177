<template lang="pug">
.q-pa-dynamic(style="margin-top:-35px;")
  q-breadcrumbs(gutter="xs").q-mt-xl.q-pt-lg
    template(v-slot:separator)
      q-icon(
          size="1.3em"
          :name="matChevronRight"
          color="primary"
          )
    q-breadcrumbs-el.text-dark(:label="$t('home')" to="/")
    q-breadcrumbs-el.text-dark(:label="$t('academy.title')" to="/submission")
    q-breadcrumbs-el.text-primary(:label="$t('submit')")
  .row.justify-center.q-col-gutter-sm.q-pt-lg.relative-position(v-if="config")
    .col-sm-9.col-md-6.col-12

      q-form.q-gutter-md(@submit="onSubmit")
        .text-h1.text-center.q-pb-sm.q-mt-xl {{ $t(`phases.${phase}.title`) }}

        //- .text-center.text-body1.text-weight-light.info.q-my-lg  {{ $t('upload_again') }}

        PhaseSubmission(
          :phase="phase"
          :files="files",
          :config="config"
          :submission="submission",
          v-on:rejected="onRejected",
          :neededFiles="neededFiles",
          :loading="loading"
        )

        .row.q-mt-lg.justify-between
          .col-6
            q-btn(
              to="/submission",
              :icon="matWest",
              size="lg",
              flat,
              square
              color="black"
            ) {{ $t('back') }}
          .col-6.text-right
            q-btn(
              color="primary",
              square
              align="between"
              style="width:300px;max-width:100%;"
              :icon-right="matNorthEast",
              unelevated,
              size="lg",
              :label="$t('submit')",
              type="Submit"
            )
    .fixed-full.dimmed.z-max(v-if="uploading")
      .dimmed
        .dimmed
          .absolute-center.text-white.text-center.z-max
            .text-h3(style="max-width: 700px; width: 80vw") {{ $t('uploading') }}
            .text-body1.q-mt-md {{ $t('dont_close') }}
            q-linear-progress.q-my-lg(size="15px", :value="progress")
</template>

<script>
  import { mapActions, mapState } from "vuex";
  import PhaseSubmission from "@/components/forms/PhaseSubmission";
  import find from "lodash/find";
  import filter from "lodash/filter";
  import Language from "@/mixins/Language";
  import { EventBus } from "@/event-bus";
  import uniq from "lodash/uniq";
  import compact from "lodash/compact";
  import map from "lodash/map";

  export default {
    name: "SubmissionForm",
    props: ["id"],
    mixins: [Language],
    components: {
      PhaseSubmission,
    },
    async created() {
      this.loading = true;
      await Promise.all([this.initConfig(), this.getSubmissions(true)]);

      // await Promise.all([
      //     await this.initConfig(),
      //     await this.getSubmissions(true),
      //   ]);

      // console.log(this.submissions);
      // console.log(find(this.submissions, { id: this.id }));

      this.submission = find(this.submissions, { id: this.id });

      if (this.submission.status != "draft")
        this.$router.replace("/submission");

      EventBus.$on("upload_progress", this.updateProgress);
      this.loading = false;
    },
    watch: {
      loading: {
        immediate: true,
        handler(val) {
          if (val) {
            this.$q.loading.show({});
          } else {
            this.$q.loading.hide();
          }
        },
      },
    },
    computed: {
      neededFiles() {
        //uploadStatus:true
        let raw = filter(this.submission.media, { type: "raw" });
        // console.log(raw);
        let array = [];

        for (let i = 0; i < raw.length; i++) {
          // console.log(raw[i]);
          // console.log(raw[i]);
          array.push(!raw[i].uploadStatus || raw[i].status == "error");
        }

        if (raw.length == 0) array.push(true, true, true, true, true);

        // console.log(array);
        return array;
        // return [true, true, false, false, false];
      },
      phase() {
        return this.config.current_phase;
      },
      ...mapState(["config", "submissions"]),
    },
    methods: {
      updateProgress(progress) {
        // console.log("progress", progress);
        this.progress = progress;
      },
      async onSubmit() {
        try {
          this.uploading = true;

          //check files are different:
          let names = compact(map(this.files, "name"));
          // console.log(uniq(names));
          // console.log(names);

          if (uniq(names).length !== names.length) {
            //not unique files:
            this.uploading = false;

            this.$q.notify({
              type: "negative",
              message: this.$t("resubmit_message"),
            });
            return;
          }

          await this.uploadMissingAction({
            id: this.submission.id,
            files: this.files,
          });

          //artificial delay to wait for the firebase function to set new state:
          setTimeout(async () => {
            await this.getSubmissions(true);
            this.$router.replace("/submission");
          }, 3000);
        } catch (e) {
          // Report failure
          console.log(e);
          this.showError(this.$t("submission_failed"));
          this.uploading = false;
        }
      },
      onRejected() {
        this.$q.notify({
          color: "secondary",
          textColor: "white",
          icon: "warning",
          message: `The video submitted should be no more than 200 MB`,
        });
      },
      ...mapActions(["uploadMissingAction", "initConfig", "getSubmissions"]),
    },
    data() {
      return {
        submission: { formdata: {} },
        files: [null, null, null, null, null],
        uploading: false,
        progress: 0,
        loading: true,
      };
    },
  };
</script>

<style src="@platyplus/humanitarian-icons/dist/icons.css">
  /* global styles */
</style>

<style lang="scss" scoped>
  .info {
    font-size: 1.5em;
  }
</style>
